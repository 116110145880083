/* ### GENERAL ### */

body{
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  color: #505050;
  overflow: hidden;
}
input,
input:focus{
  box-shadow: none;
  outline: none;
}
button:hover,
button:focus{
  outline: none;
  box-shadow: none;
}
.modal-content{
  border-radius: 8px;
  border: none;
}
.modal-body{
  padding: 0;
}

/* ### PAGE LOADING ### */

/* #load{
  width: 100%;
  height: 100vh;
  background-color: #6A0CAC;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
} */
/* ### LOGIN & SIGNUP PAGES ### */

.LoginForm-SideContent{
  background-image: url('../media/images/dots-left.svg');
  background-size: cover;
  width: 100%;
}
.LoginForm-Side{
  width: 35rem;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.LoginForm-Content{
  width: 100%;
}
.DigiReporing-Brand{
  position: absolute;
  top: 15px;
  left: 0;
}
.LoginForm-Title{
  margin-bottom: 25px;
}
.LoginForm-Description{
  margin-bottom: 20px;
  line-height: 26px;
}
.CopyRight-Text{
  position: absolute;
  left: 0;
  bottom: 15px;
  color: #BDBDBD;
  font-size: 12px;
}
.LoginForm-Title{
  font-size: 30px;
  font-weight: 700;
  color: #6A0CAC;
  margin-top: 35px;
}
.Input-Row{
  position: relative;
  margin-top: 10px;
}
.Custom-Input-Row{
  position: relative;
  margin-top: 0;
}
.Input-Row i{
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 20px;
  color: #6A0CAC;
}
.DRInput-Class.error i{
  color: #F44336;
}
.Input-Row label.error{
  position: absolute;
  top: 18px;
  right: 15px;
  font-size: 12px;
  margin: 0;
  color: #F44336;
}
.Input-Tags{
  background-color: #fff;
  border-radius: 8px;
  font-size: 12px;
  border: none;
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
.Input-Tags input[type="text"]{
  font-size: 12px;
  border: none;
  background-color: transparent;
}
.Input-Tags .tag{
  background: #6A0CAC;
  border-radius: 8px;
  color: #fff;
  padding: 5px 15px;
}
.Filter-Row{
  display: flex;
}
.Filter-BTN{
  background-color: #fff;
  color: #6A0CAC;
  border-radius: 8px;
  font-size: 12px;
  border: none;
  height: 40px;
  padding: 0 15px;
  margin: 0 5px;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.Filter-BTN:first-child{
  margin-left: 0;
}
.Filter-BTN:last-child{
  margin-right: 0;
}
.Filter-BTN.mixitup-control-active{
  background-color: #6A0CAC;
  color: #fff;
}
.Filter-BTN:hover{
  cursor: pointer;
  background-color: #6A0CAC;
  color: #fff;
}
.DRInput-Class{
  width: 100%;
  height: 50px;
  background-color: #FAF2FF;
  border: 2px solid #FAF2FF;
  border-radius: 8px;
  padding-left: 40px;
  transition: all .30s linear;
  box-shadow: none;
  outline: none;
}
.DRTextarea-Class{
  height: 100px;
  padding-top: 15px;
}
.DRInput-Class:focus{
  border-color: #6A0CAC;
  box-shadow: none;
}
.DRInput-Class.error,
.DRInput-Class.error:focus{
  border-color: #F44336;
  box-shadow: none;
}
.Input-Row .input-group-addon i{
  left: unset;
  right: 10px;
}
.toggle-password{
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  color: #610CAC;
}
.toggle-password:hover{
  cursor: pointer;
}
.LoginForm-Actions{
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  margin-top: 30px;
}
.LoginForm-Action{
  flex: 0 0 50%;
  max-width: 50%;
}
.LoginForm-Action a{
  transition: all .15s linear;
}
.LoginForm-Action a:hover{
  color: #610CAC;
  text-decoration: none;
}
.Action-Left label{
  color: #BDBDBD;
}
.Action-Right{
  text-align: right;
}
.CheckBox-Field{
  position: relative;
  float: left;
}
.container-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
}
.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.container-checkbox .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 4px;
  background-color: #FAF2FF;
  border: 1px solid #FAF2FF;
  box-shadow: 0 0 10px rgba(0,0,0, .02);
  border-radius: 4px;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.container-checkbox:hover input ~ .checkmark {
    background-color: #FAF2FF;
}
.container-checkbox input:checked ~ .checkmark {
    background-color: #6A0CAC;
    border-color: #6A0CAC;
}
.container-checkbox .checkmark::before {
    content: "";
    position: absolute;
    display: none;
}
.container-checkbox input:checked ~ .checkmark::before {
    display: block;
}
.container-checkbox .checkmark::before {
  left: 0.5px;
  top: -2px;
  font-size: 12px;
  content: '\e9c3';
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  color: #fff;
}
.remember-me{
  opacity: 0;
}
.forgot-password{
  color: #BDBDBD;
}
.FormText{
  font-size: 12px;
  line-height: 20px;
  color: #BDBDBD;
  margin-top: 30px;
}
.FormText p{
  margin-bottom: 15px;
}
.FormText p:last-child{
  margin-bottom: 0px;
}
.FormText a{
  color: #0D1BB8;
}
.Form-Submit{
  color: #FAF2FF;
  background: #6A0CAC;
  border: 2px solid #6A0CAC;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 500;
  width: 100%;
  height: 50px;
  font-size: 16px;
  margin-top: 15px;
  transition: all .15s linear;
}
.Form-Submit:hover{
  background-color: #fff;
  color: #610CAC;
  cursor: pointer;
}
.Form-Submit .uil{
  font-size: 24px;
}
.SocialConnect-Options{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}
.SocialConnect-Col{
  flex: 0 0 50%;
  max-width: 50%;
}
.SocialConnect-Col label{
  margin: 0;
}
.SocialConnect-Buttons{
  text-align: right;
}
.SocialConnect-Options button{
  width: 40px;
  height: 40px;
  background-color: #FAF2FF;
  border-radius: 8px;
  border: none;
  font-size: 22px;
  color: #6A0CAC;
  transition: all .15s linear;
}
.SocialConnect-Options button:hover{
  background: #6A0CAC;
  color: #FAF2FF;
  cursor: pointer;
}
.uim-svg{
  height: auto !important;
  vertical-align: 0.1em !important;
}
.Features-Side{
  position: relative;
  height: 100vh;
  background: rgb(106,12,172);
  background: linear-gradient(0deg, rgba(106,12,172,1) 0%, rgba(64,0,109,1) 46%);
  background-image: url('../media/images/right-side.png');
  background-size: cover;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.Side-NoMargin{
  margin-left: -15px;
  margin-right: -15px;
}
.FeaturesSide-Content{
  min-height: 0;
  min-width: 0;
}
.CreateAccount-BTN{
  position: absolute;
  top: 25px;
  right: 30px;
  color: #fff;
}
.CreateAccount-BTNMobile{
  position: relative;
}
.CreateAccount-BTN label{
  margin-right: 10px;
}
.CreateAccount-BTN a{
  text-transform: uppercase;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  padding: 8px 15px;
  border-radius: 8px;
  transition: all .15s linear;
}
.CreateAccount-BTN a:hover{
  background-color: #fff;
  color: #6A0CAC;
  text-decoration: none;
}
.FeaturesCarousel-Slider{
  margin-top: 65px;
}
.Feature-Content{
  width: 380px;
  margin: 15px auto 0;
  color: #fff;
}
.Feature-Title{
  font-size: 28px;
  font-weight: 900;
  margin: 0;
}
.Feature-Text{
  font-size: 14px;
  line-height: 24px;
  margin: 15px auto 15px;
  width: 370px;
}
.FeaturesCarousel-Slider .slick-dots{
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FeaturesCarousel-Slider .slick-dots li{
  list-style-type: none;
}
.FeaturesCarousel-Slider .slick-arrow{
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 2px solid #fff;
  opacity: 1;
  text-align: center;
  line-height: 28px;
  color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  padding: 0;
  font-size: 0;
  z-index: 9;
  transform: scale(1);
  transition: all .15s linear;
}
.FeaturesCarousel-Slider .slick-arrow.slick-prev{
  left: 35px;
}
.FeaturesCarousel-Slider .slick-arrow.slick-next{
  right: 35px;
}
.FeaturesCarousel-Slider .slick-arrow.slick-prev::before{
  content: "\eb79";
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  font-size: 18px;
}
.FeaturesCarousel-Slider .slick-arrow.slick-next::before{
  content: "\eb6d";
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  font-size: 18px;
}
.FeaturesCarousel-Slider .slick-dots button{
  width: 10px;
  height: 10px;
  display: block;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #FAF2FF;
  font-size: 0;
  margin: 0 3px;
  opacity: .5;
  transform: scale(1);
  transition: all .15s linear;
}
.FeaturesCarousel-Slider .slick-dots .slick-active button{
  opacity: 1;
  transform: scale(.8);
  cursor: pointer;
}
.FeaturesCarousel-Slider button:hover{
  opacity: 1;
  transform: scale(.8);
  cursor: pointer;
}
.LoginForm{
  position: relative;
  /* top: 30px; */
}
.LoginForm fieldset:not(:first-of-type){
  display: none;
}
.LoginForm fieldset{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.LoginForm fieldset button:hover{
  cursor: pointer;
}
.Create-Programme{
  text-align: center;
}
.Create-Programme .Create-BTN{
  background: #FAF2FF;
  border: 3px solid #FAF2FF;
  border-radius: 8px;
  padding: 28px;
  margin: 0 5px;
  transition: all .15s linear;
}
.Create-Programme .Create-BTN:hover{
  border-color: #0D1BB8;
  background-color: transparent;
  cursor: pointer;
}
.Create-Programme .Create-BTN:last-child{
  margin: 0;
}
.Create-Programme .Create-BTN label{
  text-transform: uppercase;
  color: #0D1BB8;
  font-weight: 700;
  margin: 22px 0 0;
}
.Create-Programme .Create-BTN i{
  background: #0D1BB8;
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  font-size: 22px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 8px;
}
.Prev-BTN{
  float: left;
}
.Next-BTN{
  float: right;
}
.Prev-BTN{
  font-size: 14px;
  text-transform: uppercase;
  color: #6A0CAC;
  background-color: transparent;
  border: none;
  height: 50px;
  line-height: 50px;
  margin-top: 15px;
}
.Prev-BTN:hover{
  text-decoration: none;
}
.Next-BTN{
  color: #FAF2FF;
  background: #6A0CAC;
  border: 2px solid #6A0CAC;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 20px;
  height: 50px;
  line-height: 46px;
  font-size: 14px;
  margin-top: 15px;
  transition: all .15s linear;
}
.Next-BTN:hover{
  color: #6A0CAC;
  background-color: transparent;
  text-decoration: none;
}
.CreateAccount-BTNMobile{
  display: none;
}

@media(max-width: 728px){

  .LoginForm-Side{
    width: 80%;
  }
  .Filter-Row{
    display: block;
  }
  .Filter-BTN{
    width: 100%;
    margin: 0 0 5px;
  }
  .DigiReporing-Brand{
    left: 0;
    /* right: 0; */
    margin: 0 auto;
    text-align: center;
  }
  .CreateAccount-BTNMobile{
    position: absolute;
    top: 7px;
    right: 0;
    text-align: right;
    display: block;
  }
  .CreateAccount-BTNMobile label{
    font-size: 10px;
  }
  .CreateAccount-BTNMobile a{
    padding: 8px 18px;
    border-radius: 8px;
    border: 2px solid #6a0cac;
    background: #6a0cac;
    color: #fff;
    display: inline-block;
    margin-top: 0px;
    text-transform: uppercase;
    font-size: 12px;
    transition: all .30s linear;
  }
  .CreateAccount-BTNMobile a:hover{
    color: #6a0cac;
    text-decoration: none;
  }
  .DigiReporing-Brand img{
    height: 55px;
  }
  .LoginForm-Title{
    text-align: center;
    font-size: 24px;
    margin-top: 35px;
  }
  .CopyRight-Text{
    width: 100%;
    text-align: center;
  }
  .Create-Programme .Create-BTN{
    padding: 18px;
    margin-bottom: 10px;
  }
  .Create-Programme .Create-BTN:last-child{
    margin-bottom: 0;
  }
  .Create-Programme .Create-BTN label{
    font-size: 12px;
    margin: 15px 0 0;
  }
  .DRInput-Class{
    font-size: 12px;
  }
  .DigiReporing-BrandHeader{
    display: flex;
    flex-wrap: wrap;
  }
  .DigiReporing-Brand{
    flex: 0 0 50%;
    max-width: 50%;
  }
  .CreateAccount-BTNMobile{
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/* ### DIGIREPORTING DROPDOWN ### */

.Selected-Programme{
  display: block;
  width: 100%;
  height: 40px;
  background-color: #FAF2FF;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  color: #505050;
  line-height: 40px;
  text-align: left;
  position: relative;
  padding-left: 15px;
  transition: all .20s cubic-bezier(.62,.26,.07,1);
}
.Selected-Programme::after {
  display: inline-block;
  content: "\eb3a";
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  border: none;
  vertical-align: none;
  position: absolute;
  top: 0px;
  right: 15px;
  font-size: 18px;
}
.Selected-Programme:hover{
  background-color: #6A0CAC;
  color: #fff;
  text-decoration: none;
}
.DigiReporting-DropDownMenu{
  width: 100%;
  position: absolute;
  top: 30px;
  right: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  background: #FAF2FF;
  border-radius: 8px;
  z-index: 9;
  box-shadow: 0 0 10px 0 rgba(0,0,0, .03);
}
.DigiReporting-DropDownMenu ul{
  margin: 0;
  padding: 0;
}
.DigiReporting-DropDownMenu ul li{
  list-style-type: none;
}
.DigiReporting-DropDownMenu ul li a{
  padding: 15px;
  color: #424242;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid #faf2ff;
  transition: all .20s linear;
}
.DigiReporting-DropDownMenu ul li a:hover{
  color: #6A0CAC;
}
.DigiReporting-DropDownMenu ul li:last-child a{
  border-bottom: none;
}
.DigiReporting-DropDownActive .DigiReporting-DropDownMenu{
  visibility: visible;
  opacity: 1;
  top: 55px;
}


/* ### MENU SIDE ### */
.Menu-Side{
  background-color: #fff;
  width: 280px;
  padding: 15px 30px;
  height: 100vh;
  position: fixed;
  overflow: auto;
  scrollbar-color: rebeccapurple transparent;
  scrollbar-width: none;
}
.Menu-Side::-webkit-scrollbar {
  width: 3px;
}
.Menu-Side::-webkit-scrollbar-track {
  border-radius: 15px;
}
.Menu-Side::-webkit-scrollbar-thumb {
  background: #BDBDBD;
  border-radius: 3px;
}
.Projects-List{
  position: relative;
  margin-top: 25px;
  width: 100%;
}
.Projects-List #dropdownMenuLink{
  display: block;
  width: 100%;
  height: 45px;
  background-color: #FAF2FF;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  color: #505050;
  line-height: 35px;
  text-align: left;
  position: relative;
  padding-left: 15px;
}
.Projects-List #dropdownMenuLink:focus{
  box-shadow: none;
}
.Projects-List #dropdownMenuLink::after{
  display: inline-block;
  content: "\eb3a";
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  border: none;
  vertical-align: none;
  color: #6A0CAC;
  position: absolute;
  top: 6px;
  right: 30px;
  font-size: 18px;
}
.Projects-List .dropdown-menu{
  padding: 0;
  width: 100%;
  border-radius: 8px;
  background: #FAF2FF;
  border: none;
}
.Projects-List .dropdown-item{
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px dashed #fff;
  transition: all .20s cubic-bezier(.62,.26,.07,1);
  position: relative;
  left: 0;
}
.Projects-List .dropdown-item .uil{
  font-size: 18px;
  position: relative;
  margin-right: 2px;
  color: #6A0CAC;
}
.Projects-List .dropdown-item:last-child{
  border-bottom: 0;
}
.Projects-List .dropdown-item .Project-Thumb{
  width: 30px;
  height: 30px;
  background-color: #505050;
  border-radius: 8px;
  margin-right: 10px;
}
.Projects-List .dropdown-item:hover{
  color: #6A0CAC;
  background-color: transparent;
  left: 5px;
}
.dropdown-divider{
  border-top: 2px solid #fff;
}
.DigiReporting-Menu{
  padding-top: 20px;
  width: calc( 100% - 10px );
}
.DigiReporting-Menu .DigiReporting-Menu-Item{
  margin-bottom: 15px;
}
.DigiReporting-Menu-Item .Menu-Link{
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #897B92;
  position: relative;
  transition: all .35s cubic-bezier(.62,.26,.07,1);
  left: 0px;
}
.DigiReporting-Menu-Item .Menu-Link:hover{
  text-decoration: none;
  color: #6A0CAC;
  left: 5px
}
.DigiReporting-Menu-Item .Menu-Link span{
  position: relative;
  top: -2px;
  margin-left: 8px;
}
.DigiReporting-Menu-Item .Menu-Link i{
  font-size: 20px;
}
/* .DigiReporting-Menu-Item.ActivePage .Menu-Link{
  color: #6A0CAC;
  left: 5px
} */
.DigiReporting-Menu-Item .Menu-Link-Active,
.HasDropDown .DropDown-Item .Menu-Link-Active {
  color: #6A0CAC;
  font-weight: 700;
  left: 5px
}
.DigiReporting-Menu-Item.HasDropDown .Menu-Link{
  left: 0px;
}
.DigiReporting-Menu-Item.ActivePage .Menu-Link::before {
  width: 5px;
  height: 100%;
  background-color: #6A0CAC;
  border-radius: 30px;
}
#DigiReporing-MagiqLine{
  position: absolute;
  right: 0px;
  top: 0;
  width: 4px;
  height: 50px;
  background: #6A0CAC;
  transition: all .15s cubic-bezier(.62,.26,.07,1);
  border-radius: 10px;
}
.dropdown-divider{
  margin: 0;
}
/* ### CONTENT SIDE ### */
.Content-Side{
  width: calc(100% - 280px);
  height: 100vh;
  margin-left: 280px;
  overflow: hidden;
}
.HeaderTop{
  width: 100%;
  height: 70px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 30px;
}
.HeaderLeft-Side{
  flex: 0 0 40%;
  max-width: 40%;
  display: flex;
  justify-content: flex-start;
}
.HeaderRight-Side{
  flex: 0 0 60%;
  max-width: 60%;
  display: flex;
  justify-content: flex-end;
}
.Search-Form{
  position: relative;
}
.Search-Form input[type="search"]{
  border: none;
  padding-left: 30px;
  color: #897B92;
}
.Search-Form button{
  border: none;
  background: none;
  position: absolute;
  left: 0;
  top: -2px;
  padding: 0;
  font-size: 18px;
  color: #897B92;
}
.UserBox{
  margin-right: 30px;
  position: relative;
}
.UserBox:hover{
  cursor: pointer;
}
.UserBox a{
  display: flex;
  color: #505050;
}
.UserBox a:hover{
  text-decoration: none;
}
.UserBox-Thumb{
  width: 40px;
  height: 40px;
  /* background-color: #6A0CAC; */
  border-radius: 8px;
  overflow: hidden;
  margin-left: 15px;
}
.UserBox-Thumb img{
  width: 100%;
}
.thumbnail-profile {
  height: 100%;
}
.UserBox-Infos{
  text-align: right;
}
.UserBox-Infos label{
  display: block;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 2px;
}
.UserBox-Infos span{
  display: block;
  color: #897B92;
  font-size: 12px;
  margin-bottom: 2px;
}
#User-DropDown{
  display: flex;
}
.User-DropDownP{
  width: 120%;
  position: absolute;
  top: 50px;
  right: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  background: #fff;
  border-radius: 8px;
  z-index: 9;
  box-shadow: 0 0 10px 0 rgba(0,0,0, .03);
}
.User-DropDownP ul{
  margin: 0;
  padding: 0;
}
.User-DropDownP ul li{
  list-style-type: none;
}
.User-DropDownP ul li a{
  padding: 15px;
  color: #424242;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid #faf2ff;
  transition: all .20s linear;
}
.User-DropDownP ul li a:hover{
  color: #6A0CAC;
}
.User-DropDownP ul li:last-child a{
  border-bottom: none;
}
.User-DropDownP ul li a i{
  font-size: 18px;
  margin-right: 8px;
  color: #6A0CAC;
}
.drop-down--active .User-DropDownP{
  visibility: visible;
  opacity: 1;
  top: 65px;
}
.AlertBox{
  position: relative;
}
.AlertBox-BTN{
  background-color: #FAF2FF;
  border-radius: 8px;
  border: 3px solid #FAF2FF;
  color: #6A0CAC;
  font-size: 24px;
}
.AlertBox-BTN:hover{
  cursor: pointer;
}
.Content-Container{
  width: 100%;
  background: #FAF2FF;
  height: calc(100vh - 70px);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 0 45px;
  overflow: auto;
  scrollbar-color: rebeccapurple transparent;
  scrollbar-width: thin;
}
.PageHeader{
  padding-top: 20px;
  margin-bottom: 5px;
}
.PageHeader-Title{
  font-size: 26px;
  font-weight: 900;
  color: #424242;
  margin-top: 10px;
}
.FilterForm .FilterRow{
  display: flex;
  justify-content: flex-end;
}
.FilterRow .Input-Row{
  margin: 0;
  margin-left: 10px;
}
.FilterRow .Input-Row:first-child{
  margin: 0;
}
.FilterForm .SelectFilter{
  background-color: #fff;
  border-radius: 8px;
  font-size: 12px;
  border: none;
  height: 40px;
  padding: 0 45px 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.FilterForm .InputField{
  background-color: #fff;
  border-radius: 8px;
  font-size: 12px;
  border: none;
  height: 40px;
  padding: 0 15px;
}
.FilterForm .Input-Date i{
  position: absolute;
  top: 5px;
  right: 10px;
  left: unset;
  font-size: 20px;
  color: #6A0CAC;
}
.Input-Select{
  position: relative;
}
.Input-Select::before{
  position: absolute;
  top: 9px;
  right: 15px;
  display: inline-block;
  content: "\eb3a";
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
}
.ChartBOX{
  background-color: #fff;
  padding: 40px 30px 30px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.DashboardStats-BOX{
  background-color: #fff;
  border-radius: 8px;
}
.Purple-BOX{
  background-color: #6A0CAC;
}
.BOX-Header{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px;
  border-bottom: 2px dashed #FAF2FF;
  position: relative;
  margin-bottom: 1em;
}
.Purple-BOX .BOX-Header{
  border-bottom: 2px dashed #7511BB;
}
.StatsBox{
  margin-bottom: 60px;
}
.StatsBox .slick-dots {
  padding: 0;
  margin: 0;
  text-align: center;
}
.StatsBox .slick-dots li{
  display: inline-block;
  list-style-type: none;
  margin: 0 3px;
}
.StatsBox .slick-dots button{
  font-size: 0;
  width: 15px;
  height: 15px;
  padding: 0;
  border: none;
  border-radius: 8px;
  background: #6A0CAC;
  border: 5px solid #6A0CAC;
  transition: all .15s linear;
  transform: scale(1);
}
.StatsBox .slick-dots button:hover{
  background: #FAF2FF;
  transform: scale(.9);
}
.StatsBox .slick-dots .slick-active button{
  background: #FAF2FF;
  transform: scale(.9);
}
.BOX-Title{
  flex: 0 0 60%;
  max-width: 60%;
  font-size: 16px;
  font-weight: 900;
  color: #505050;
  margin: 0;
  line-height: 35px;
}

.BOX-Title.BOX-Title-Form{
  flex: 0 0 100%;
  max-width: 100%;
}

.Purple-BOX .BOX-Title{
  color: #FAF2FF;
}
.StartupsBOX-List{
  flex: 0 0 40%;
  max-width: 40%;
  justify-content: flex-end;
  display: flex;
  text-align: right;
}
.StartupsBOX-List .StartupBOX-Thumb{
  width: 35px;
  height: 35px;
  background-color: #A1A1A1;
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  margin-left: -18px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-size: 12px;
}
.StartupsBOX-List .StartupBOX-Thumb.ShowMore{
  background-color: #6A0CAC;
}
.Purple-BOX .StartupBOX-Thumb{
  background-color: #FAF2FF;
}
.BOX-Content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px 0;
}
.Purple-BOX .BOX-Content{
  padding: 0;
}
.Purple-BOX .BOX-Side-1{
  width: 100%;
  padding: 26.5px 0;
  border-bottom: 1px dashed #7511BB;
}
.BOX-Side-2{
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}
.BOX-Side-Icon{
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #6A0CAC;
  color: #fff;
  font-size: 22px;
  text-align: center;
  line-height: 38px;
  margin: 0 auto;
  display: block;
}
.BOX-Side-Content span{
  font-size: 2.5vw;
  font-weight: 900;
  line-height: 56px;
  margin-top: 10px;
  color: #6A0CAC;
  display: block;
}
.Purple-BOX .BOX-Side-Content span{
  color: #FAF2FF;
}
.BOX-Side-Content label{
  font-size: 12px;
  color: #505050;
  display: block;
  margin: 0;
}
.Purple-BOX .BOX-Side-Content label{
  color: #FAF2FF;
}
.BOX-Footer{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 15px 0;
  border-top: 2px dashed #FAF2FF;
}
.BOX-Label{
  font-size: 12px;
  color: #505050;
  margin: 0;
}
.Investi{
  font-size: 16px;
  color: #505050;
  display: block;
  text-align: right;
}
.Total{
  font-size: 22px;
  font-weight: 900;
  color: #6A0CAC;
  display: block;
  text-align: right;
}
.Mobile-Header{
  display: none;
}
.ToggleUser{
  line-height: 10px;
}

@media(min-width: 768px) and (max-width: 989px){

  .BOX-Side-2{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .BOX-Label{
    margin-bottom: 10px;
  }
  .BOX-Footer{
    text-align: center;
  }
  .Investi{
    text-align: center;
  }
  .Total{
    text-align: center;
  }

}

@media(max-width: 768px){

  .Menu-Side{
    left: -280px;
    top: 0;
    transition: all .15s linear;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 9;
  }
  .Content-Side{
    width: 100%;
    margin: 0;
  }
  .AlertBox-BTN,
  .UserBox,
  .HeaderLeft-Side{
    display: none;
  }
  .Mobile-Header{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .HeaderTop{
    height: 0;
  }
  .LeftSide,
  .CenterSide,
  .RightSide{
    padding: 10px 0;
  }
  .UserBox-Thumb{
    margin: 0;
  }
  .Mobile-Header .Search-Box{
    flex: 0 0 100%;
    max-width: 100%;
    border-top: 1px dashed #ededed;
    height: 50px;
    display: none;
  }
  .Mobile-Header .Search-Box input[type="search"]{
    width: 100%;
    height: 50px;
    padding-left: 12px;
    font-size: 12px;
  }
  .Mobile-Header .Search-Box button{
    right: 2px;
    left: unset;
    color: #333;
    top: 5px;
  }
  .Mobile-Header button{
    background-color: transparent;
    font-size: 26px;
    padding: 0 10px;
    border: none;
  }
  .LeftSide{
    display: flex;
    flex: 0 0 37.5%;
    max-width: 37.5%;
    justify-content: flex-start;
    align-items: center;
  }
  .RightSide{
    display: flex;
    flex: 0 0 37.5%;
    max-width: 37.5%;
    justify-content: flex-end;
    align-items: center;
  }
  .CenterSide{
    display: flex;
    flex: 0 0 25%;
    max-width: 25%;
    justify-content: center;
    align-items: center;
  }
  .DigiReporing-BrandResponsive img{
    height: 45px;
  }
  .ToggledMenu-Side{
    left: 0;
  }
  .Content-Container{
    padding: 0 15px;
  }
  .PageHeader{
    padding-top: 20px;
    margin-bottom: 5px;
  }
  .PageHeader-Title{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .FilterRow .Input-Row{
    width: 100%;
  }
  .FilterForm .SelectFilter{
    width: 100%;
  }
  .Input-Select::before{
    top: 15px;
  }
  .ChartBOX{
    margin-bottom: 15px;
    padding: 20px 10px 10px;
  }
  .DashboardStats-BOX{
    margin-bottom: 15px;
  }
  .BOX-Content{
    padding: 20px 0;
  }
  .BOX-Header{
    padding: 10px 15px;
  }
  .BOX-Footer{
    padding: 10px 0;
  }
  .BOX-Side-Content span{
    font-size: 30px;
    line-height: 36px;
  }
  .Purple-BOX .BOX-Side-1{
    padding: 17.9px 0;
  }
}

/* USERS LIST */

.modal-backdrop.show{
  opacity: 0.7;
}
.modal-backdrop{
  background: #e6caee;
}
.ModalUsers-Dialog{
  max-width: 850px;
}
.ModalUsers-Content{
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0,0,0, .05);
}
.ModelUsers-Header{
  padding: 30px 30px 0;
}
.ModelUsers-Header h4{
  margin: 0;
  color: #6A0CAC;
  font-size: 28px;
  font-weight: 900;
}
.ModalUsers-Content .table-responsive{
  padding: 0 15px;
}
.ModalUsers-Content .dataTables_paginate{
  margin: 10px 0;
}
.MembersList-Container{
  padding: 30px;
}
.MembersList-Header{
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  height: 50px;
  border-bottom: 2px solid #FAF2FF;
  font-size: 12px;
  font-weight: 500;
}
.MembersList-Items{
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  height: 50px;
  font-size: 12px;
  color: #897B92;
}
.MembersList-Items:not(:last-child){
  border-bottom: 1px solid #FAF2FF;
}
.MembersList-HeaderName,
.MembersList-RowUserInfo{
  flex: 0 0 28%;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.MembersList-HeaderActivity,
.MembersList-RowUserActivity{
  flex: 0 0 13%;
  display: flex;
  align-items: center;
}
.MembersList-HeaderSecteur,
.MembersList-RowUserSecteur{
  flex: 0 0 18%;
  display: flex;
  align-items: center;
}
.MembersList-HeaderEmail,
.MembersList-RowUserEmail{
  flex: 0 0 24%;
  display: flex;
  align-items: center;
}
.MembersList-HeaderPhone,
.MembersList-RowUserPhone{
  flex: 0 0 15%;
  display: flex;
  align-items: center;
}
.MembersList-RowUserInfo{
  display: flex;
  align-items: center;
}
.D-RowUserInfo{
display: inline-flex;
align-items: center;
}
.D-RowUserInfo a{
  display: inline-block;
  color: #505050;
  transition: all .20s linear;
}
.D-RowUserInfo a:hover{
  color: #6A0CAC;
}
.MembersList-RowUserInfoThumb{
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #6A0CAC;
  border-radius: 8px;
  margin-right: 10px;
}
.MembersList-RowUserPhone a,
.MembersList-RowUserEmail a{
  display: none;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #F6F6F6;
  border-radius: 8px;
  margin-left: 8px;
  color: #6A0CAC;
  font-size: 14px;
  transition: all .15s linear;
}
.MembersList-Items:hover .MembersList-RowUserPhone a,
.MembersList-Items:hover .MembersList-RowUserEmail a{
  display: block;
}
.MembersList-Items:hover .MembersList-RowUserPhone a:hover,
.MembersList-Items:hover .MembersList-RowUserEmail a:hover{
  background-color: #6A0CAC;
  color: #FAF2FF;
}
.Close-BTN{
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #6a0cac;
  font-size: 25px;
  transform: scale(1);
  transition: all .20s linear;
  box-shadow: none;
  outline: none;
}
.Close-BTN:focus,
.Close-BTN:hover{
  transform: scale(1.1);
  background: transparent;
  cursor: pointer;
}
.CloseMenu-Side{
  display: none;
}
.CloseToggleAlerts{
  display: none;
}
.CloseToggleProfile{
  display: none;
}

@media(max-width: 768px){
  .ToggleProfile-Infos{
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 8px;
    font-size: 12px;
    border: none;
    margin-top: 15px;
  }
  .MembersList-Container{
    overflow: auto;
  }
  .CloseMenu-Side{
    display: block;
    position: fixed;
    right: 15px;
    top: 15px;
    background: #fff;
    color: #6A0CAC;
    opacity: 0;
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0,0,0, .05);
    visibility: hidden;
    font-size: 26px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
    transition: all .20s linear;
  }
  .ToggledMenu-Side .CloseMenu-Side{
    visibility: visible;
    opacity: 1;
  }
  .CloseToggleAlerts{
    display: block;
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all .10s linear;
  }
  .Toggled-CloseToggleAlerts{
    opacity: 1;
    visibility: visible;
  }
  .CloseToggleAlerts-BTN{
    display: block;
    background: #fff;
    color: #6A0CAC;
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0,0,0, .05);
    font-size: 26px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
  }
  .ToggledMenu-Side .CloseToggleAlerts .CloseToggleAlerts-BTN{
    visibility: visible;
    opacity: 1;
  }
  .CloseToggleProfile{
    display: block;
    position: fixed;
    right: 10px;
    top: 15px;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all .10s linear;
  }
  .Toggled-DetailsPage-Sidebar .CloseToggleProfile{
    opacity: 1;
    visibility: visible;
  }
  .CloseToggleProfile-BTN{
    display: block;
    background: #fff;
    color: #6A0CAC;
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0,0,0, .05);
    font-size: 26px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
  }
}

.ShowMore:hover{
  cursor: pointer;
}
.NotificationsContainer{
  position: absolute;
  top: 56px;
  right: -330px;
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 10px 30px 0 rgba(0,0,0, .05);
  z-index: 9;
  width: 300px;
  height: calc(100vh - 70px);
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.Toggled-NotificationsContainer{
  right: -30px;
}
.NotificationsContainer h4{
  font-size: 20px;
  font-weight: 900;
  display: block;
  border-bottom: 1px dashed #F5E8FD;
  padding: 20px 30px;
  margin: 0;
}
.NotificationItem{
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-bottom: 1px dashed #F5E8FD;
}
.NotificationItem:last-child{
  border-bottom: none;
}
.NotificationItem-Thumb{
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: #000;
  border-radius: 8px;
}
.NotificationItem-Text{
  font-size: 12px;
  display: block;
}
.NotificationsItems{
  padding-top: 15px;
  padding-bottom: 15px;
}
.NotificationItem-Time{
  font-size: 12px;
  display: block;
  color: #897B92;
  margin-top: 4px;
}
.Notifications-ShowMore{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  border-top: 1px solid #faf2ff;
  text-align: center;
  line-height: 50px;
  color: #897B92;
  font-weight: 500;
  transition: all .15s linear;
  background: #fff;
}
.Notifications-ShowMore:hover{
  color: #6A0CAC;
  text-decoration: none;
}

/* width */
.Content-Container::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.Content-Container::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 15px;
}
/* Handle */
.Content-Container::-webkit-scrollbar-thumb {
  background: #BDBDBD;
  border-radius: 5px;
}
@media(max-width: 768px){
  .NotificationsContainer{
    top: 0;
    height: 100vh;
    position: fixed;
  }
  .Toggled-NotificationsContainer{
    right: 0;
  }

  .HiddenClass1 .Content-Container,
  .HiddenClass2 .Content-Container{
    overflow: hidden;
    opacity: .1;
  }
  .HiddenClass2 .Search-Box,
  .HiddenClass2 .RightSide{
    opacity: .1;
  }
  .HiddenClass1 .Search-Box,
  .HiddenClass1 .LeftSide{
    opacity: .1;
  }
  .Menu-Side{
    box-shadow: 0 10px 30px 0 rgba(0,0,0, .05);
  }
  .NotificationsContainer h4{
    padding: 20px 30px;
  }
  .NotificationItem{
    padding: 10px 30px;
  }
}

/* ### STARTUPS LIST ### */

.StartUPBOX{
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 30px;
}
.StartUPBOX-Thumb{
  width: 100%;
  height: auto;
  background: rgb(106,12,172);
  background: -moz-linear-gradient(165deg, rgba(106,12,172,0.7) 0%, rgba(85,10,138,1) 50%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(165deg, rgba(106,12,172,0.7) 0%, rgba(85,10,138,1) 50%, rgba(0,0,0,1) 100%);
  background: linear-gradient(165deg, rgba(106,12,172,0.7) 0%, rgba(85,10,138,1) 50%, rgba(0,0,0,1) 100%);
  /* background: linear-gradient(119deg, rgba(106,12,172,1) 0%, rgba(85,10,138,1) 100%); */
  background-size: 400% 400%;
  background-repeat: no-repeat;
  border-radius: 8px;
  overflow: hidden;
  transition: all .50s cubic-bezier(.62,.26,.07,1);
}
.StartUPBOX-Thumb a{
  display: block;
  height: 100%;
}
.StartUPBOX-Thumb img{
  width: 100%;
  opacity: 1;
  transform: scale(1);
  transition: all .20s cubic-bezier(.62,.26,.07,1);
  /* max-height: 10em; */
}
.StartUPBOX:hover .StartUPBOX-Thumb{
  background-size: 100% 100%;
}
.StartUPBOX:hover .StartUPBOX-Thumb img{
  opacity: .3;
  transform: scale(1.06);
}
.StartUPBOX-Details{
  margin: 25px 0 15px;
  text-align: center;
}
.StartUPBOX-DetailsName{
  font-size: 16px;
  font-weight: 700;
}
.StartUPBOX-DetailsLink{
  color: #424242;
  transition: all .20s linear;
}
.StartUPBOX:hover .StartUPBOX-DetailsLink{
  color: #6A0CAC;
  text-decoration: none;
}

.optionListContainer {
  position: relative !important;
}

.StartUPBOX-DetailsBudget {
  font-weight: 700;
  color: #6A0CAC;
  font-size: 16px;
  display: block;
  max-width: 95%;
  margin: auto;
}
.StartUPBOX-DetailsSector{
  font-weight: 400;
  color: #6A0CAC;
  font-size: 12px;
  display: block;
  margin-bottom: 10px;
}
.ManagerBOX .StartUPBOX-DetailsRole{
  font-weight: 400;
  font-size: 12px;
  display: block;
  margin-top: 10px;
}
.ManagerBOX .StartUPBOX-DetailsProfile{
  font-weight: 400;
  color: #6A0CAC;
  font-size: 12px;
  display: block;
  margin-top: 10px;
}
.StartUPBOX-DetailsDate{
  background-color: #FAF2FF;
  padding: 5px 8px;
  color: #424242;
  display: inline-block;
  border-radius: 6px;
  margin-top: 10px;
  font-size: 12px;
}
.StartUPBOX-Active{
  position: absolute;
  top: 15px;
  left: 30px;
  width: 18px;
  height: 18px;
  display: block;
  background: #6A0CAC;
  border: 5px solid #fff;
  z-index: 9;
  border-radius: 8px;
}
.StartUPBOX-Inactive{
  position: absolute;
  top: 15px;
  left: 30px;
  width: 18px;
  height: 18px;
  display: block;
  background: #BDBDBD;
  border: 5px solid #fff;
  z-index: 9;
  border-radius: 8px;
}

/* ### STARTUP DETAILS ### */
.DetailsPage-Container{
  width: calc(100% - 300px);
  height: calc(100vh - 70px);
  background-color: #FAF2FF;
  border-radius: 8px;
  overflow: auto;
  scrollbar-color: rebeccapurple transparent;
  scrollbar-width: thin;
}
.DetailsPage-Container::-webkit-scrollbar {
  width: 5px;
}
.DetailsPage-Container::-webkit-scrollbar-track {
  border-radius: 15px;
}
.DetailsPage-Container::-webkit-scrollbar-thumb {
  background: #BDBDBD;
  border-radius: 5px;
}
.DetailsPage-Content{
  padding: 0 60px 35px;
}
.DetailsPage-Cover{
  width: 100%;
  height: 180px;
  background-color: #6A0CAC;
  margin-bottom: 35px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.DetailsPage-Desc{
  background-color: #6A0CAC;
  color: #FAF2FF;
  padding: 35px;
  border-radius: 8px;
  margin: 0 8px;
}
.DetailsPage-Desc h3{
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.DetailsPage-Desc p{
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
}
.DetailsPage-Statistics{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 0;
}
.DetailsPage-StatisticsBOX{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 7.5px;
}
.StatisticsBOX-Content{
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  padding: 20px 0;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
  margin-bottom: 20px;
}
.StatisticsBOX-Content .uil{
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: #FAF2FF;
  display: block;
  margin: 0 auto 15px;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
}
.StatisticsBOX-Numbers{
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: #424242;
  margin-bottom: 5px;
}
.StatisticsBOX-Label{
  display: block;
  font-size: 12px;
  color: #897B92;
  margin: 0;
}
.StartUPChartBOX{
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 30px;
  margin: 0 8px;
}
.StartUPChartBOX .FilterForm{
  padding-right: 20px;
}
.StartUPChartBOX .FilterForm .SelectFilter{
  margin-bottom: 15px;
}
.FilterForm .SelectFilter.LightedColor{
  background-color: #FAF2FF;
}
.StartUP-BOXES{
  margin: 15px 8px 0;
}
.ReportBOX{
  background-color: #fff;
  border-radius: 8px;
}
.ReportBOX-Header{
  padding: 20px;
  border-bottom: 2px solid #FAF2FF;
}
.ReportBOX-HeaderTitle{
  font-size: 16px;
  color: #6A0CAC;
  font-weight: 700;
  margin: 0;
}
.ReportBOX-HeaderTitle .uil{
  font-size: 22px;
  margin-right: 6px;
}
.ReportBOX-List{
  margin: 0;
  padding: 0;
}
.ReportBOX-ListItem{
  display: flex;
  flex-wrap: wrap;
  padding: 19px 20px;
  border-bottom: 1px solid #FAF2FF;
  align-items: center;
}
.ParticipationLabel{
  flex: 0 0 50%;
  max-width: 50%;
  margin: 0;
}
.ParticipationNumbers{
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
}
.Completed span{
  background-color: #E8F5E9;
  border-radius: 8px;
  padding: 5px 10px;
  color: #66BB6A;
  font-size: 12px;
}
.NotCompleted span{
  background-color: #FFEBEE;
  border-radius: 8px;
  padding: 5px 10px;
  color: #EF5350;
  font-size: 12px;
}
.RedBOX .uil{
  background-color: #FFEBEE;
  color: #F44336;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.GreenBOX .uil{
  background-color: #E8F5E9;
  color: #4CAF50;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.BlueBOX .uil{
  background-color: #E3F2FD;
  color: #2196F3;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.OrangeBOX .uil{
  background-color: #FFF3E0;
  color: #FF9800;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.DetailsPage-StatisticsBOX.RedBOX:hover .StatisticsBOX-Content{
  background-color: #FFEBEE;
}
.DetailsPage-StatisticsBOX.RedBOX:hover .uil{
  background-color: #fff;
}
.DetailsPage-StatisticsBOX.GreenBOX:hover .StatisticsBOX-Content{
  background-color: #E8F5E9;
}
.DetailsPage-StatisticsBOX.GreenBOX:hover .uil{
  background-color: #fff;
}
.DetailsPage-StatisticsBOX.BlueBOX:hover .StatisticsBOX-Content{
  background-color: #E8F5E9;
}
.DetailsPage-StatisticsBOX.BlueBOX:hover .uil{
  background-color: #fff;
}
.DetailsPage-StatisticsBOX.OrangeBOX:hover .StatisticsBOX-Content{
  background-color: #FFF3E0;
}
.DetailsPage-StatisticsBOX.OrangeBOX:hover .uil{
  background-color: #fff;
}
.TeamBOX{
  background-color: #6A0CAC;
  border-radius: 8px;
}
.WhiteBox{
  background-color: transparent;
}
.TeamBOX-Header{
  padding: 20px;
  border-bottom: 2px solid #760DBF;
  position: relative;
}
.WhiteBox .TeamBOX-Header{
  border-bottom: 2px solid #f6eefb;
}
.TeamBOX-HeaderTitle {
  font-size: 16px;
  color: #FAF2FF;
  font-weight: 700;
  margin: 0;
}
.WhiteBox .TeamBOX-ListItem{
  color: #505050;
  border-bottom: 1px solid #f6eefb;
}
.TeamBOX-HeaderTitle .uil{
  font-size: 22px;
  margin-right: 6px;
}
.MembersList{
  position: absolute;
  top: 22px;
  right: 15px;
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: #FAF2FF;
}
.TeamBOX-List{
  padding: 0;
  margin: 0;
}
.TeamBOX-ListItem{
  list-style-type: none;
  color: #FAF2FF;
  display: flex;
  padding: 15.4px 20px;
  border-bottom: 1px solid #760DBF;
}
.TeamBOX-ListItem:last-child{
  border-bottom: none;
}
.TeamBOX-MemberDetails{
  line-height: 20px;
}
.TeamBOX-MemberDetails .MemberDetails-Name{
  display: block;
}
.TeamBOX-MemberDetails .MemberDetails-Email{
  display: block;
  font-size: 11px;
}
.TeamBOX-MemberDetails .MemberDetails-Function{
  display: block;
  font-size: 11px;
}
.TeamBOX-MemberAvatar{
  width: 60px;
  height: 60px;
  background-color: #FAF2FF;
  border-radius: 8px;
  margin-right: 10px;
}
.DetailsPage-Sidebar{
  height: calc(100vh - 70px);
  width: 300px;
  overflow: auto;
  scrollbar-color: rebeccapurple transparent;
  scrollbar-width: thin;transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.StartUPInfos-Header{
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #FAF2FF;
}
.StartUPInfos-Thumb{
  width: 6rem;
  /* height: 8rem; */
  border-radius: 8px;
  overflow: hidden;
  background-color: #6A0CAC;
  margin: 0 auto 10px;
  font-size: 22px;
  color: #FAF2FF;
  font-weight: 700;
  line-height: 58px;
}
.StartUPInfos-Name{
  font-size: 16px;
  font-weight: 700;
  color: #6A0CAC;
  margin-bottom: 8px;
}
.StartUPInfos-Identiant{
  font-size: 12px;
  color: #897B92;
}
.StartUPInfos-List{
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid #FAF2FF;
}
.StartUPInfos-Item{
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 6px 20px;
}
.StartUPInfos-Item:first-child{
  padding-top: 0;
}
.StartUPInfos-Item:last-child{
  padding-bottom: 0;
}
.StartUPInfos-ItemIcon{
  display: block;
  width: 40px;
  height: 40px;
  background-color: #FAF2FF;
  font-size: 18px;
  line-height: 40px;
  color: #6A0CAC;
  text-align: center;
  border-radius: 8px;
  margin-right: 10px;
}
.StartUPInfos-ItemLabel{
  display: block;
  font-size: 12px;
  color: #897B92;
  font-weight: 400;
  margin: 0 0 2px;
}
.StartUPInfos-ItemValue{
  display: block;
  font-size: 12px;
  color: #424242;
  font-weight: 700;
}
.StartUPInfos-Contact{
  margin: 0;
  padding: 20px;
}
.StartUPInfos-ContactItem{
  list-style-type: none;
  font-size: 12px;
  line-height: 28px;
}
.StartUPInfos-ContactItem a{
  color: #0D1BB8;
}
.StartUPInfos-ContactItem a:hover{
  text-decoration: none;
}
.GrayLink a{
  color: #897B92;
}
.StartUPInfos-ContactItem i{
  margin-right: 10px;
  font-size: 18px;
}
.StartUPInfos-Contact h4{
  font-size: 18px;
  color: #424242;
  font-weight: 700;
  margin-bottom: 15px;
}
.StartUPInfos-Footer{
  padding: 0 20px;
}
.StartUPInfos-Footer label{
  margin: 0 20px 0 0;
  font-size: 12px;
  font-weight: 700;
  color: #424242;
}
.StartUPInfos-Footer span{
  font-size: 12px;
  font-weight: 400;
  color: #424242;
}

@media(max-width: 768px){
  .StartUPChartBOX{
    padding: 10px;
  }
}

#ModalMembers .MembersList-HeaderPhone,
#ModalMembers .MembersList-RowUserPhone{
  flex: 0 0 20%;
}
#ModalMembers .MembersList-HeaderEmail,
#ModalMembers .MembersList-RowUserEmail{
  flex: 0 0 30%;
}
#ModalMembers .MembersList-HeaderName,
#ModalMembers .MembersList-RowUserInfo{
  flex: 0 0 30%;
}
#ModalMembers .MembersList-HeaderSecteur,
#ModalMembers .MembersList-RowUserSecteur{
  flex: 0 0 20%;
}
.MissionsContainer{
  margin: 0 -5px;
}
.MissionsContainer .slick-arrow-left{
  position: absolute;
  top: -75px;
  right: 45px;
  height: 40px;
  width: 40px;
  border: 2px solid #6A0CAC;
  border-radius: 8px;
  background-color: #fff;
  font-size: 24px;
  padding: 0;
  color: #6A0CAC;
  transition: all .25s cubic-bezier(.62,.26,.07,1);
}
.MissionsContainer .slick-arrow-right{
  position: absolute;
  top: -75px;
  right: 0;
  height: 40px;
  width: 40px;
  border: 2px solid #6A0CAC;
  border-radius: 8px;
  background-color: #fff;
  font-size: 24px;
  padding: 0;
  color: #6A0CAC;
  transition: all .25s cubic-bezier(.62,.26,.07,1);
}
.MissionsContainer .slick-arrow:hover{
  background-color: #6A0CAC;
  color: #fff;
  cursor: pointer;
}
.MessionsBOX{
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  margin: 15px 8px 0;
  position: relative;
}
.MessionsBOX-Header{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 35px;
}
.MessionsBOX-Header h4{
  flex: 0 0 50%;
  max-width: 50%;
  font-size: 26px;
  color: #424242;
  font-weight: 700;
  margin: 0;
}
.MessionsBOX-Header .FilterForm{
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 95px;
}
.slick-slide{
  padding: 0 5px;
}
.MissionBOX{
  background-color: #FAF2FF;
  border-radius: 8px;
  padding: 40px 30px;
  text-align: center;
  position: relative;
}
.MissionBOX .MissionBOX-Done{
  font-size: 24px;
  color: #388E3C;
  display: block;
  line-height: 0;
  width: 30px;
  height: 30px;
  position: relative;
  margin: 0 auto 20px;
  background-color: #fff;
  border-radius: 8px;
  line-height: 30px;
}
.MissionBOX .MissionBOX-NotDone{
  font-size: 18px;
  color: #D32F2F;
  display: block;
  line-height: 0;
  width: 30px;
  height: 30px;
  position: relative;
  margin: 0 auto 20px;
  background-color: #fff;
  border-radius: 8px;
  line-height: 28px;
}
.MissionBOX-Date{
  color: #424242;
}
.MissionBOX-Title{
  font-size: 14px;
  font-weight: 700;
  color: #6A0CAC;
  margin: 15px 0;
  display: block;
}
.ParticipantsMembers{
  display: flex;
  justify-content: center;
}
.ParticipantMemeber{
  width: 35px;
  height: 35px;
  display: block;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #A1A1A1;
  margin: 0 -8px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  line-height: 35px
}
#ReportPie{
  padding: 18px 20px 0;
}
.BudgetStatus{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
  padding: 15px 0 20px;
}
.BudgetStatus span{
  font-weight: 900;
  margin-bottom: 5px;
  display: block;
}
.BudgetStatus-Expend{
  text-align: left;
  flex: 0 0 70%;
  max-width: 70%;
  color: #6A0CAC;
}
.BudgetStatus-Expend::after{
  content: "";
  width: 100%;
  height: 5px;
  background-color: #6A0CAC;
  display: block;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.BudgetStatus-Rest{
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  color: #897B92;
}
.BudgetStatus-Rest::after{
  content: "";
  width: 100%;
  height: 5px;
  background-color: #897B92;
  display: block;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.FinancingBOX .BOX-Side-1{
  padding: 12px 0px;
}
.FinancingBOX .BOX-Side-1:last-child{
border-bottom: 0;
}
.ReportView{
  position: absolute;
  top: 25px;
  right: 25px;
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: #6A0CAC;
  padding: 0;
}
.Budget label,
.Budget span{
  color: #897B92;
}
.ReportBOX-Header{
  position: relative;
}
.DetailsPage-Cover{
  position: relative;
}
.DetailsPage-Cover .StartUPInfos-Header{
  display: none;
}

@media(max-width: 768px){

  .StatisticsBOX-Content{
    padding: 20px 0;
  }
  .DetailsPage-Sidebar{
    position: fixed;
    right: -300px;
  }
  .DetailsPage-Sidebar .StartUPInfos-Header{
    display: none;
  }
  .DetailsPage-Container{
    width: 100%;
  }
  .DetailsPage-Cover .StartUPInfos-Header{
    display: block;
    position: relative;
    top: 130px;
  }
  .DetailsPage-Cover{
    position: relative;
    margin-bottom: 165px;
  }
  .DetailsPage-Content{
    padding: 0 35px 35px;
  }
  .DetailsPage-Desc{
    padding: 30px 30px 20px;
  }
  .DetailsPage-Desc h3{
    font-size: 20px;
  }
  .DetailsPage-Desc p{
    font-size: 12px;
  }
  .DetailsPage-Sidebar{
    background-color: #fff;
  }
  .Toggled-DetailsPage-Sidebar{
    right: 0;
  }
  .StartUP-BOXES .slick-dots {
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .StartUP-BOXES .slick-dots li {
    display: inline-block;
    list-style-type: none;
    margin: 0 3px;
  }
  .StartUP-BOXES .slick-dots button {
    font-size: 0;
    width: 15px;
    height: 15px;
    padding: 0;
    border: none;
    border-radius: 8px;
    background: #6A0CAC;
    border: 5px solid #6A0CAC;
    transition: all .15s linear;
    transform: scale(1);
  }
  .StartUP-BOXES .slick-dots button:hover {
    background: #FAF2FF;
    transform: scale(.9);
  }
  .StartUP-BOXES{
    padding-bottom: 60px;
  }
  #ReportPie{
    padding: 35px 20px 0;
  }
  .BudgetStatus{
    padding: 30px 0 20px;
  }
  .FinancingBOX .BOX-Side-1{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .DetailsPage-StatisticsBOX{
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 15px;
  }
  .MessionsBOX-Header{
    margin-bottom: 15px;
  }
  .MessionsBOX-Header .FilterForm{
    margin-top: 20px;
  }
  .MessionsBOX-Header .FilterForm{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .MissionsContainer .slick-arrow-right,
  .MissionsContainer .slick-arrow-left{
    height: 40px;
    top: -60px;
  }
  .StartUP-BOXES{
    margin-right: 4px;
    margin-left: 4px;
  }
  .MessionsBOX-Header h4{
    font-size: 24px;
  }
  .ReportView{
    top: 20px;
  }
  .FilterRow .Input-Row:first-child{
    margin-bottom: 5px;
  }
  .FilterForm .FilterRow{
    flex-wrap: wrap;
  }
  .FilterRow .Input-Row{
    max-width: 100%;
    flex: unset;
    margin-bottom: 5px;
    margin-left: 0;
    width: auto;
  }
  .FilterForm .InputField{
    width: 100%;
  }
  .Input-Select::before {
    top: 10px;
  }
  .StartUPBOX{
    margin-bottom: 15px;
  }
}

/* SETTINGS PAGE */

.SettingsRow{
  display: flex;
  padding: 0 15px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.Settings-Container{
  height: calc(100vh - 70px);
  overflow: auto;
  scrollbar-color: rebeccapurple transparent;
  scrollbar-width: thin;
  width: 100%;
  background: #FAF2FF;
  overflow-x: hidden;
}
.SettingsMenu-Title{
  font-size: 26px;
  font-weight: 700;
  color: #424242;
  margin-bottom: 35px;
}
.Settings-Items{
  padding: 0;
  margin: 0;
  position: relative;
}
.Settings-Item{
  list-style-type: none;
  line-height: 50px;
  position: relative;
}
.Settings-Item a{
  color: #424242;
  font-size: 14px;
  font-weight: 500;
  transition: all .20s linear;
}
.Settings-Item-Active a,
.Settings-Item a:hover{
  color: #6A0CAC;
  text-decoration: none;
}
.Settings-Item i{
  color: #6A0CAC;
  font-size: 20px;
  margin-right: 10px;
}
.DropDown-Items{
  display: none;
}
.DropDown-Items{
  margin: 20px 0 0 30px;
  padding: 0;
}
.DropDown-Item{
  list-style-type: none;
  padding-bottom: 15px;
}
.DropDown-Item:last-child{
  padding: 0;
}
.DropDown-Item .Menu-Link{
  font-size: 14px;
  font-weight: 500;
  color: #897B92;
  transition: all .25s cubic-bezier(.62,.26,.07,1);
  position: relative;
}
.HasDropDown .Menu-Link{
  /* color: #6A0CAC; */
}
.HasDropDown .Menu-Link .uil-angle-right{
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(0);
  transition: all .25s cubic-bezier(.62,.26,.07,1);
}
.HasDropDown.DropDown-Active .Menu-Link .uil-angle-right{
  transform: rotate(90deg);
}
@media(max-width: 768px){

  .SettingsRow{
    display: block;
  }
  .SettingsMenu-Title{
    display: none;
  }
  .SettingsMenu{
    width: auto;
    overflow: auto;
    white-space: nowrap;
    padding: 0px 15px;
    border-top: 1px solid #faf2ff;
    border-radius: 0;
    margin-top: 1px;
  }
  .Settings-Items{
    display: flex;
  }
  .Settings-Item a{
    display: flex;
    padding: 0 15px;
  }
  .Settings-Item i{
    font-size: 16px;
    margin-right: 6px;
  }

}

.SettingsContent{
  width: 100%;
  padding: 0 40px;
}
.SettingsContent-Title{
  font-size: 22px;
  font-weight: 700;
  color: #424242;
  line-height: 42px;
}
.UsersProfiles-ROW{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.UserProfile-BOX{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 5px;
}
.UserProfile-BOXWrap{
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
}
.UserProfile-BOX:last-child{
  margin-right: 0;
}
.UserProfile-COUNT{
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  display: block;
  background-color: #000;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  border-radius: 6px;
  margin-right: 8px;
  font-size: 14px;
}
.Pink_Color{
  background-color: #E7357D;
}
.Orange_Color{
  background-color: #EC6929;
}
.Yellow_Color{
  background-color: #F7A931;
}
.Propal_Color{
  background-color: #553766;
}
.UserProfile-LABEL{
  font-size: 12px;
}
.table{
  background-color: #fff;
  border-radius: 8px;
  font-size: 12px;
  margin-top: 15px;
  border: none;
}
.table-striped tbody tr:nth-of-type(2n+1){
  background-color: #fff;
}
.table thead th{
  white-space: nowrap;
  border-top: 0;
  font-weight: 700;
  color: #897B92;
  border: none;
  border-bottom: 2px solid #FAF2FF;
  padding: 21px 15.5px;
}
.table tfoot th{
  border-top: 0;
  font-weight: 700;
  color: #897B92;
  border: none;
  border-top: 2px solid #FAF2FF;
  padding: 21px 15.5px;
}
.table td{
  padding: 15.5px;
  border: none;
  border-top: 1px solid #FAF2FF;
  vertical-align: middle;
}
.table th{
  border: none;
  border-top: 1px solid #FAF2FF;
}
.TableActions-Form{
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.TableActions-FormRow .Input-Row{
  margin: 0;
}
.TableActions-FormRowLeft{
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  align-items: center;
  justify-content: flex-start;
}
.TableActions-FormRowLeft button{
  height: 40px;
  margin: 0 5px;
}
.TableActions-FormRowLeft button:first-child{
  margin-left: 0px;
}
.TableActions-FormRowLeft button:last-child{
  margin-right: 0px;
}
.TableActions-FormRowRight{
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  justify-content: flex-end;
}
.TableActions-FormRowRight input[type="search"]{
  height: 40px;
  width: 180px;
  border: 2px solid #FAF2FF;
  border-radius: 8px;
  padding: 0 15px;
  box-shadow: none;
  outline: 0;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.TableActions-FormRowRight input[type="search"]:focus{
  border-color: #6A0CAC;
}
.TableActions-Form .SelectAction{
  background-color: #fff;
  border-radius: 8px;
  font-size: 12px;
  border: none;
  height: 40px;
  padding: 0 45px 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.AddNew{
  background-color: #6A0CAC;
  border: 2px solid #6A0CAC;
  color: #FAF2FF;
  font-size: 12px;
  border-radius: 8px;
  padding: 0 15px;
  text-transform: uppercase;
  height: 40px;
  font-weight: 700;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.AddNew:hover{
  background-color: transparent;
  color: #6A0CAC;
  cursor: pointer;
}
.AddNew i{
  margin-left: 6px;
}
.Porpal-BTN{
  background-color: #6A0CAC;
  border: 2px solid #6A0CAC;
  color: #FAF2FF;
  font-size: 12px;
  border-radius: 8px;
  padding: 0 15px;
  text-transform: uppercase;
  height: 40px;
  line-height: 38px;
  font-weight: 700;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.Porpal-BTN:hover{
  background-color: transparent;
  color: #6A0CAC;
  cursor: pointer;
  text-decoration: none;
}
.Porpal-BTN i{
  margin-left: 6px;
}
.Red-BTN{
  background-color: #fff;
  border: 2px solid #fff;
  color: #D32F2F;
  font-size: 18px;
  border-radius: 8px;
  padding: 0;
  text-transform: uppercase;
  height: 40px;
  width: 40px;
  font-weight: 700;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.Red-BTN:hover{
  background-color: #D32F2F;
  border-color: #D32F2F;
  color: #FFF;
  cursor: pointer;
}
.Red-BTN i{
  margin-left: 0px;
}
.buttons-remove.disabled{
  width: 40px;
  padding: 0;
  opacity: .5;
}
.buttons-remove span{
  display: none;
}
.buttons-remove::before{
  content: "\e97a";
  display: inline-block;
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
}
.White-BTN{
  background-color: #fff;
  border: 2px solid #fff;
  color: #6A0CAC;
  font-size: 18px;
  border-radius: 8px;
  padding: 0;
  text-transform: uppercase;
  height: 40px;
  width: 40px;
  font-weight: 700;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.White-BTN:hover{
  background-color: #6A0CAC;
  border-color: #6A0CAC;
  color: #FFF;
  cursor: pointer;
}
.White-BTN i{
  margin-left: 0px;
}
.EditLink-BTN {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons-edit.disabled{
  width: 40px;
  padding: 0;
  opacity: .5;
}
.buttons-edit span{
  display: none;
}
.buttons-edit::before{
  content: "\e98e";
  display: inline-block;
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
}
.dataTables_paginate{
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 20px 0 0;
}
.PaginationPage-Item{
  list-style-type: none;
}
.PaginationPage-Item{
  margin-right: 6px;
}
.PaginationPage-Item:last-child{
  margin-right: 0px;
}
.page-item .page-link{
  display: block;
  background-color: #fff;
  color: #6A0CAC;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.paginate_button{
  margin: 0 5px;
}
.paginate_button:last-child{
  margin-right: 0;
}
.paginate_button:first-child{
  margin-left: 0;
}
.paginate_button.page-item.next .page-link,
.paginate_button.page-item.previous .page-link{
  width: auto;
  padding: 0 15px;
}
.page-item .page-link:hover{
  background-color: #6A0CAC;
  color: #FAF2FF;
  text-decoration: none;
}
.page-item.active .page-link{
  background-color: #6A0CAC;
  color: #FAF2FF;
}

@media(max-width:768px){

  .Settings-Container{
    width: 100%;
    padding: 0 15px 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .SettingsContent{
    padding: 0;
  }
  .UserProfile-BOX{
    flex: 0 0 50%;
    max-width: 50%;
    padding: 5px;
  }

}
.SettingsContent-Wrap{
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
}
.SettingsContent-Section{
  padding-bottom: 30px;
}
.SettingsContent-Section:last-child{
  padding-bottom: 0;
}
.SettingsContent-SectionTitle{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.FieldsRow{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.Settings-Field{
  position: relative;
}
.Settings-Field-3{
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 0 10px;
}
.Settings-Field-1{
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 10px;
}
.Settings-Field-6{
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 10px;
}
.SettingCol-Row{
  display: flex;
  flex-wrap: wrap;
}
.SettingCol-Header{
  position: relative;
  padding: 0 10px;
  margin-bottom: 20px;
}
.DigiReprting-Modal .modal-body p{
  padding: 0 10px;
}
.SettingCol-HeaderTitle{
  font-size: 20px;
  font-weight: 700;
  color: #6A0CAC;
}
.ProfileEdit-BTN{
  width: 30px;
  height: 30px;
  background: #FAF2FF;
  border-radius: 8px;
  color: #6A0CAC;
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 10px;
  border: none;
  padding: 0;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.ProfileEdit-BTN:hover{
  background-color: #6A0CAC;
  color: #fff;
}
.LightBG{
  background-color: #FAF2FF;
}
.WhiteBG{
  background-color: #FFFFFF;
}

.Settings-FieldRow {
  position: relative;
  border-radius: 8px;
  display: table;
  clear: both;
  width: 100%;
  margin-bottom: 10px;
}

.montant-operation {
  padding-top: 5em;
}

.Custom-FieldRow {
  padding: 15px;
}

.Settings-FieldRowOption{
  padding: 0 15px;
}
.Settings-FieldRow:last-child{
  margin: 0;
}
.Settings-FieldRow label{
  margin: 0;
  font-size: 12px;
  font-weight: 700;
}
.Settings-FieldRow.Label-PlaceHolder label{
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}
.Settings-FieldRow .FieldText{
  height: 50px;
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 15px;
  transition: all .15s linear;
}

textarea.FieldText {
  min-height: 4rem;
}

#StartDate {
  margin-top: 0;
}

.LightBG .FieldText{
  background-color: #FAF2FF;
  border: 2px solid #FAF2FF;
}
.Settings-FieldRow .FieldText:focus{
  border-color: #6A0CAC;
}
.Settings-FieldRow_Select .FieldRow-Select{
  width: 100%;
  height: 50px;
  font-size: 14px;
  padding: 15px;
  margin: 0;
  top: 0;
  border-radius: 8px;
  transition: all .15s linear;
}
/* .Settings-FieldRow_Select.LightBG .FieldRow-Select{
  background-color: #FAF2FF;
  border: 2px solid #FAF2FF;
} */
.Settings-FieldRow_Select .FieldRow-Select {
  width: 100%;
  font-size: 14px;
  margin: 0;
}
.Settings-FieldRow_Select.LightBG .FieldRow-Select:focus{
  border-color: #6A0CAC;
}
.Settings-FieldRow_Date .input-group-addon{
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 20px;
}
.Settings-FieldRow_Tags .bootstrap-tagsinput input[type="text"]{
  background-color: transparent;
  border: none;
}
.Settings-FieldRow_Tags .bootstrap-tagsinput .tag{
  background: #6A0CAC;
  border-radius: 8px;
  color: #fff;
  padding: 5px 15px;
}
.Settings-FieldRow_FileUpload{
  height: 50px;
  padding: 0 15px;
}
.Settings-FieldRow_FileUpload label{
  line-height: 50px;
}
.FieldRow-Select{
  font-size: 12px;
  color: #505050;
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  float: right;
  position: relative;
  top: 2px;
}
.FieldRow_RadioGroup{
  position: absolute;
  right: 25px;
  top: 15px;
  display: flex;
}
.FieldRow_RadioInput{
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
}
.FieldRow_RadioInput input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.FieldRow_Upload{
  position: absolute;
  right: 15px;
  top: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.FieldRow_Upload input[type="file"]{
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  opacity: 0;
}
.FieldRow_Upload span{
  font-size: 25px;
}
.FieldRow_UploadBTN{
  position: relative;
}
.FieldRow_Radio input[type="radio"]:checked + label,
.FieldRow_Radio input[type="radio"]:not(:checked) + label{
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 18px;
  display: inline-block;
  color: #666;
}
.FieldRow_Radio input[type="radio"]{
  opacity: 0;
}
.FieldRow_Radio input[type="radio"] + label::before,
.FieldRow_Radio input[type="radio"]:not(:checked) + label::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: #fff;
}
.FieldRow_Radio input[type="radio"]:checked + label::after,
.FieldRow_Radio input[type="radio"]:not(:checked) + label::after{
  content: '';
  width: 12px;
  height: 12px;
  background: #6A0CAC;
  position: absolute;
  top: 3px;
  left: 2.84px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.FieldRow_Radio input[type="radio"]:not(:checked) + label::after{
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.FieldRow_Radio input[type="radio"]:checked + label::after{
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.FieldRow-Update{
  float: right;
  font-size: 12px;
  line-height: 12px;
}
.FieldRow-Update .EditField-BTN{
  margin-left: 15px;
  background-color: transparent;
  border: none;
  color: #6A0CAC;
  font-size: 20px;
}
.Cover-Preview{
  height: 120px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}
.Input-Row .Done-BTN{
  background-color: #731BB1;
  height: 40px;
  padding: 0 30px;
  border-radius: 8px;
  color: #fff;
  display: flex;
}
.Input-Row .Done-BTN{
  background-color: #731BB1;
  border: 2px solid #731BB1;
  height: 40px;
  padding: 0 15px;
  font-size: 12px;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.Input-Row .Transparent-BTN{
  background-color: transparent;
  border: 2px solid transparent;
  height: 40px;
  padding: 0 15px;
  font-size: 12px;
  border-radius: 8px;
  color: #731BB1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.Input-Row .Done-BTN i{
  position: relative;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 16px;
  margin-right: 6px;
}
.Input-Row .Transparent-BTN i{
  position: relative;
  top: 0;
  left: 0;
  color: #731BB1;
  font-size: 16px;
  margin-right: 6px;
}
.Input-Row .Done-BTN:hover{
  color: #731BB1;
  background-color: transparent;
  cursor: pointer;
}
.Input-Row .Done-BTN:hover i{
  color: #731BB1;
}
button:hover{
  cursor: pointer;
}
@media(max-width: 768px){
  .montant-operation {
    padding-top: 0;
  }
  .Input-Row .Transparent-BTN span{
    display: none;
  }
  .SettingsContent-Wrap{
    padding: 20px;
  }
  .SettingsContent-SectionTitle{
    font-size: 18px;
  }
  .Settings-Field.Settings-Field-3{
    text-align: center;
    padding: 15px 5px;
  }
  .Settings-Field-3{
    padding: 0 5px;
  }
  .Settings-FieldRow label{
    font-weight: 700;
  }
  .FieldRow-Select{
    width: 100%;
    text-align: left;
    margin-top: 8px;
    float: unset;
  }
  .FieldRow-Update .EditField-BTN {
    margin-left: 5px;
    font-size: 18px;
    padding: 0;
  }
  .MarginBottom-15{
    margin-bottom: 5px;
  }
  .SettingsContent-Section{
    padding-bottom: 15px;
  }

}
.MarginBottom-15{
  margin-bottom: 15px;
}
.MarginBottom-15:last-child{
  margin-bottom: 0;
}
.SettingsCols-Row{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.DisplayFlex{
  display: flex;
}
.SettingCol-3{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 5px;
}
.SettingCol-4{
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 0 7.5px;
}
.SettingCol-6{
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 5px;
}
.SettingCol-8{
  flex: 0 0 66.66%;
  max-width: 66.66%;
  padding: 0 5px;
}
.SettingCol-9{
  flex: 0 0 75%;
  max-width: 75%;
  padding: 0 5px;
}
.MarginTop-15{
  margin-top: 15px;
}
.FlexView{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BillingItem{
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 10px;
  padding: 25px 0 30px;
}
.BillingItem-Label{
  font-size: 12px;
  color: #6A0CAC;
  margin: 0;
}
.BillingItem-Value{
  font-size: 34px;
  color: #424242;
  font-weight: 700;
}
.BillingItem-Value span{
  font-size: 14px;
  font-weight: 400;
}
.BillingItem-Info{
  font-size: 12px;
  color: #424242;
  display: block;
  margin-top: 2px;
}
.BillingItem-Actions{
  margin-top: 20px;
}
.BillingItem-Action{
  background: #6A0CAC;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 8px;
  border: 2px solid #6A0CAC;
  color: #FAF2FF;
  line-height: 14px;
  padding: 13px 15px;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.BillingItem-Action:hover{
  background: #fff;
  color: #6A0CAC;
  cursor: pointer;
}
.BillingMethod .BillingItem-Value{
  margin-top: 20px;
}
.SettingsContent .DetailsPage-Statistics{
  margin: 0 -5px;
}
.Tabs-Wraps{
  margin-top: 0;
}
.TabContent{
  background: #fff;
  border-radius: 8px;
  border-top-left-radius: 0px;
}
.TabContent .TableActions-Form{
  margin-top: 0;
  padding: 15px 15px 0;
}
.TabContent .TableActions-Form .SelectAction{
  background-color: #FAF2FF;
}
.SettingsContent .nav-item:first-child .nav-link{
  border-top-left-radius: 8px;
}
.SettingsContent .nav-item:last-child .nav-link{
  border-top-right-radius: 8px;
  border-right: 0;
}
.SettingsContent .nav-tabs{
  border-bottom: 0;
}
.SettingsContent .nav-tabs .nav-link{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 13.5px 20px;
  font-size: 14px;
  color: #897B92;
  font-weight: 500;
  border: 2px solid #FAF2FF;
  border-left: 0;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.SettingsContent .nav-tabs .nav-link.active{
  color: #6A0CAC;
  border-bottom: 2px solid #fff;
}
.SettingsContent .nav-tabs .nav-link:hover{
  color: #6A0CAC;
}
.SettingsContent .nav-tabs .nav-link i{
  font-size: 20px;
  margin-right: 10px;
}

@media(max-width: 768px){

  .SettingsContent .nav-tabs .nav-link{
    padding: 8.5px 12px;
    font-size: 12px;
  }
  .SettingCol-6{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .SettingsContent .nav-tabs{
    justify-content: center;
  }
  .SettingsContent .nav-tabs .nav-link{
    border-radius: 8px;
  }
  .nav-tabs .nav-item{
    padding: 2px;
  }
  .TabContent{
    margin-top: 5px;
    border-radius: 8px;
  }
  .Tabs-Wraps{
    margin-bottom: 30px;
  }
  .dataTables_paginate{
    margin: 0;
    justify-content: center;
  }

}
.TableWrap tbody tr{
  background-color: #fff;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.TableWrap tbody th{
  padding: 16px 15.5px;
}
.TableWrap tbody tr:hover{
  background-color: #FAF2FF;
}
.TableWrap tbody tr:hover .container-checkbox .checkmark{
  background-color: #fff;
  border-color: #fff;
}
.TableWrap tbody tr:hover .container-checkbox input:checked ~ .checkmark{
  background-color: #6A0CAC;
  border-color: #6A0CAC;
  color: #FAF2FF;
}
.arrow-up-green::before{
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background: url('../../assets/media/images/arrow-up-right-green.png') no-repeat;
  background-size: contain;
  margin-right: 4px;
}
.arrow-down-red::before{
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background: url('../../assets/media/images/arrow-down-right-red.png') no-repeat;
  background-size: contain;
  margin-right: 4px;
}
.SettingsContent .ReportBOX-Body{
  position: relative;
  padding: 0;
}
.SettingsContent .ReportBOX-BodyTitle{
  position: absolute;
  left: 80px;
  top: 30px;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #424242;
}
.SettingsStats .BOX-Side-Icon{
  width: 60px;
  height: 60px;
  font-size: 32px;
  line-height: 58px;
}
.SettingsStats .BOX-Side-Content span{
  font-size: 65px;
  margin-top: 15px;
}
.SettingsStats .BOX-Side-Content label{
  font-size: 16px;
  margin-top: 8px;
}
.PreuveBTN{
  background-color: #6A0CAC;
  border: 2px solid #6A0CAC;
  color: #FAF2FF;
  font-size: 14px;
  border-radius: 8px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  padding: 0;
}
.datepicker{
  padding: 15px;
  border-radius: 8px;
  border: none;
}
.datepicker tbody td{
  text-align: center;
  font-size: 12px;
  padding: 6px;
  border: 2px solid #fff;
  transition: all .20s linear;
}
.datepicker tbody td:hover{
  cursor: pointer;
}
.datepicker thead th{
  text-align: center;
}
.old.day{
  color: #897B92;
  opacity: .4;
}
.today.day{
  background-color: #FAF2FF;
  color: #6A0CAC;
  border-radius: 8px;
  font-weight: 700;
}
.active.day{
  background-color: #6A0CAC;
  color: #FAF2FF;
}
.day:hover{
  background-color: #6A0CAC;
  color: #FAF2FF;
}
.day{
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  transition: all .20s linear;
}
.dow{
  font-size: 14px;
  text-align: center;
  padding: 8px 10px;
}
.datepicker-switch{
  text-align: center;
  padding-bottom: 10px;
}
.next,
.prev{
  padding-bottom: 10px;
}
.month{
  background: #FAF2FF;
  display: inline-block;
  margin: 2px;
  border-radius: 8px;
  width: 60px;
  height: 40px;
  line-height: 42px;
}

@media(max-width: 768px){

  .BillingPage .SettingCol-8,
  .BillingPage .SettingCol-4{
    max-width: 100%;
    flex: 0 0 100%
  }
  .BillingPage .BillingItem{
    padding: 15px 0 20px;
  }
  .BillingPage .BillingItem-Actions{
    margin-top: 10px;
  }
  .BillingPage .BillingItem-Value{
    font-size: 26px;
  }
  .BillingPage .BillingItem-Action{
    padding: 10px 12px;
  }
  .SettingCol-4{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .SettingCol-8{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .SettingsStats .BOX-Side-Icon{
    width: 45px;
    height: 45px;
    font-size: 24px;
    line-height: 45px
  }
  .SettingsStats .BOX-Side-Content span{
    font-size: 45px;
  }
  .SettingsStats .BOX-Side-Content label{
    font-size: 14px;
  }

}

/* DATATABLE MODAL */
.DTED_Lightbox_Background{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255, .8);
}
.DTED_Lightbox_Wrapper{
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DTED_Lightbox_Container{
  position: relative;
  width: 600px;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(0,0,0, .1);
}
.DTE_Header_Content{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 0 6px;
}
.DTE_Field{
  position: relative;
  padding: 8px 15px;
  border: 2px solid #fff;
  border-radius: 8px;
  background-color: #FAF2FF;
  width: 100%;
  margin: 0;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.DTE_Field:focus + .DTE_Field{
  border-color: #6A0CAC;
}
.DTE_Field label{
  font-weight: 700;
  margin-bottom: 6px;
  font-size: 12px;
  color: #6A0CAC;
}
.DTE_Field_InputControl input{
  background-color: transparent;
  width: 100%;
  border: none;
}
.DTE_Field_InputControl select{
  background-color: transparent;
  width: 100%;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.DigiReprting-Modal textarea{
  background-color: transparent;
  width: 100%;
  border: none;
}
.DTE_Field_Type_select .DTE_Field_InputControl{
  position: relative;
}
.DTE_Field_Type_select .DTE_Field_InputControl::before{
  content: "\eb3a";
  display: inline-block;
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  position: absolute;
  top: -3px;
  right: 0;
}
.DTE_Form_Content{
  display: flex;
  flex-wrap: wrap;
}
.Digi-Col-6{
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 5px;
}
.Digi-Col-12{
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 5px;
}

.DTE_Form_Buttons{
  display: flex;
  justify-content: flex-end;
  padding: 0 6px;
  margin-top: 10px;
}
.DTE_Form_Buttons button{
  background-color: #6A0CAC;
  border: 2px solid #6A0CAC;
  color: #FAF2FF;
  font-size: 14px;
  border-radius: 8px;
  padding: 5px 30px;
  text-transform: uppercase;
  height: 50px;
  font-weight: 700;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
.DTED_Lightbox_Close{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
}
.DTED_Lightbox_Close::before{
  content: "\e9b3";
  display: inline-block;
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #6a0cac;
}
.DTED_Lightbox_Close:hover{
  cursor: pointer;
}
.select-checkbox{
  position: relative;
}
.select-checkbox::before{
  display: block;
  position: absolute;
  top: 1.2em;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  content: '';
  height: 15px;
  width: 15px;
  border-radius: 4px;
  background-color: #FAF2FF;
  border: 1px solid #FAF2FF;
  box-shadow: 0 0 10px rgba(0,0,0, .02);
  border-radius: 4px;
  transition: all .45s cubic-bezier(.62,.26,.07,1);
}
tr.selected .select-checkbox::before{
  background-color: #6a0cac;
  border-color: #6a0cac;
}
.select-checkbox::after{
  position: absolute;
  top: .64em;
  left: -7px;
  right: 0;
  margin: 0 auto;
  content: "\e9c3";
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #6a0cac;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  display: block;
  transform: scale(.5);
  opacity: 0;
}
tr.selected .select-checkbox::after{
  opacity: 1;
  transform: scale(1);
  color: #fff;
  transition: all .15s cubic-bezier(.62,.26,.07,1);
}
.upload.limitHide{
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.upload.limitHide button{
  font-size: 0;
  background: #333;
  border: 2px solid #333;
  color: #fff;
  transition: all .15s cubic-bezier(.62,.26,.07,1);
}
.upload.limitHide button:hover{
  background-color: transparent;
}
.clearValue{
  display: none;
}
.upload.limitHide button::before{
  content: "\eaed";
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  font-size: 18px;
}
.DTE_Field_Type_upload .row.second{
  display: block;
  text-align: left;
  padding-left: 15px;
  font-size: 12px;
}
.msg-error{
  font-size: 12px;
}
.DTE_Field_Type_upload .drop{
  margin-bottom: 5px;
}
.upload.limitHide input[type="file"]{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.Settings-FieldRow_Upload{
  position: relative;
}
.UploadImage{
  position: absolute;
  bottom: 0;
  right: 0;
}
.UploadImage label{
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.UploadImage input[type="file"]{
  width: 50px;
  height: 50px;
  opacity: 0;
  border-radius: 8px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99;
}
.UploadImage input[type="file"]:hover{
  cursor: pointer;
}
@media(max-width: 768px){

  .DTED_Lightbox_Container{
    width: 90%;
    padding: 20px;
  }
  .DTE_Field{
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0 0 5px;
  }
  .DTE_Header_Content{
    font-size: 18px;
  }

}

/* ### CALENDAR ### */

.editor-datetime{
  background-color: #fff;
  position: absolute;
  background-color: white;
  z-index: 2050;
  /* border: 1px solid rgb(204, 204, 204); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 30px 0px;
  padding: 0;
  border-radius: 8px;
}
.editor-datetime-title{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #FAF2FF;
  padding: 0 30px;
}
.editor-datetime-iconRight{
  position: absolute;
  right: 15px;
  top: 16px;
  font-size: 0;
}
.editor-datetime-iconRight button::before{
  content: "\eb6d";
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}
.editor-datetime-iconLeft{
  position: absolute;
  left: 15px;
  top: 16px;
  font-size: 0;
}
.editor-datetime-iconLeft button::before{
  content: "\eb79";
  font-family: 'unicons-line';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}
.editor-datetime-iconLeft button,
.editor-datetime-iconRight button{
  padding: 0;
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 8px;
  background: #faf2ff;
  color: #6a0cac;
  line-height: 24px;
  transition: all .15s cubic-bezier(.62,.26,.07,1);
}
.editor-datetime-iconLeft button:hover,
.editor-datetime-iconRight button:hover{
  cursor: pointer;
  color: #faf2ff;
  background: #6a0cac;
}
.editor-datetime-label span{
  display: none;
}
.editor-datetime-label select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}
.editor-datetime-calendar{
  padding: 10px 15px;
}
.editor-datetime-table{
  text-align: center;
  padding: 30px;
}
.editor-datetime-table thead{
  height: 50px;
}
.editor-datetime-button{
  background-color: transparent;
  border: none;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  transition: all .15s cubic-bezier(.62,.26,.07,1);
}
.editor-datetime-button:hover{
  background-color: #faf2ff;
  cursor: pointer;
}
.selected .editor-datetime-button{
  background-color: #6A0CAC;
  color: #fff;
}
.Flux-Activities_List .NotificationItem{
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
}
.Flux-Activities_List .NotificationLink{
  background: #6A0CAC;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 8px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.Col-Padding_15{
  padding: 20px 15px;
  border-radius: 8px;
  position: relative;
}
.FollowUp-Form .Settings-Field{
  margin-bottom: 10px;
}
.FollowUp-Form .Settings-Field:last-child{
  margin-bottom: 0;
}

/* ### BILAN ### */

.BilanWrap{
  padding: 30px;
}
.BilanRow{
  background-color: #FAF2FF;
  border-radius: 8px;
  margin-bottom: 30px;
}
.BilanRow:last-child{
  margin-bottom: 0px;
}
.BilanRow-Title{
  font-size: 18px;
  font-weight: 700;
  color: #6A0CAC;
  padding: 20px 15px 10px;
}
.BilanRow-Item{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid  #fff;
  padding: 0 15px;
}
.BilanResualts-Row{
  height: 70px;
}
.BilanItem-Title{
  flex: 0 0 50%;
  max-width: 50%;
  text-align: left;
  font-weight: 700;
}
.BilanResualts-Row .BilanItem-Title h4{
  font-weight: 700;
  font-size: 18px;
  color: #6A0CAC;
  margin: 0;
}
.BilanResualts-Row .BilanItem-Title label{
  font-weight: 400;
  font-size: 12px;
  color: #424242;
  margin: 5px 0 0;
}
.BilanItem-Value{
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}
.BilanResualts-Row .BilanItem-Value div{
  font-weight: 700;
  font-size: 18px;
  color: #6A0CAC;
  margin: 0;
}
.BilanResualts-Row .BilanItem-Value label{
  font-weight: 400;
  font-size: 12px;
  color: #424242;
  margin: 0 0 0;
}
.tab-pane .FilterForm{
  padding: 30px 30px 0 0px;
}
.EmailAlert{
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 100%;
}
.EmailAlert .EmailAlert-Text{
  flex: 0 0 70%;
  max-width: 70%;
  justify-content: flex-start;
  display: flex;
  font-size: 12px;
  color: #505050;
}
.EmailAlert .EmailAlert-Text p{
  margin: 0;
}
.EmailAlert-Text i{
  font-size: 18px;
  color: #EF5350;
}
.EmailAlert .EmailAlert-Action{
  flex: 0 0 30%;
  max-width: 30%;
  justify-content: flex-end;
  display: flex;
}
.EmailAlert .EmailAlert-Action .EmailConfirmation-BTN{
  background-color: #EF5350;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
  height: 30px;
  padding: 5px 10px;
  border: none;
}
.Height-100{
  height: 100%;
}

.modal-content, .DigiReprting-Modal .white-bg {
  background-color: white !important;
}

.cursorPointer {
  cursor: pointer;
}

.DigiReprting-Modal .modal-content{
  border-radius: 8px;
  overflow: hidden !important;
  box-shadow: 0 0 20px 0 rgba(0,0,0, .05);
  background-color: #fff !important;
}
.DigiReprting-Modal .Modal-LightBG{
  background-color: #FAF2FF !important;
}
.DigiReprting-Modal .modal-body{
  padding: 30px 15px 20px;
}
.DigiReprting-Modal .SettingCol-HeaderTitle{
  margin-bottom: 20px;
}
.DigiReprting-Modal .Settings-Field{
  margin-bottom: 10px;
}
.DigiReprting-Modal .Settings-Field:last-child{
  margin-bottom: 0;
}
.DigiReprting-Modal .modal-footer{
  background-color: #FAF2FF;
  padding: 20px 25px;
  display: flex;
}
.ButtonCancel{
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding-left: 10px;
}
.ButtonSave{
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
.Save-BTN{
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  text-transform: uppercase;
  background-color: #6A0CAC;
  padding: 5px 15px;
  border: 2px solid #6A0CAC;
  color: #fff;
}
.Cancel-BTN{
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  text-transform: uppercase;
  background-color: #EF5350;
  padding: 5px 15px;
  border: 2px solid #EF5350;
  color: #fff;
}

.btn {
  margin-left: .5rem;
  text-transform: none;
}
.close{
  position: absolute;
  top: -15px;
  right: 0;
}
.UserProfile-Update{
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.UserProfile-UpdateThumb{
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-color: #6A0CAC;
  position: relative;
  margin-right: 15px;
}
.UserProfile-UpdateThumb button{
  width: 26px;
  height: 26px;
  text-align: center;
  color: #6A0CAC;
  font-size: 16px;
  line-height: 26px;
  padding: 0;
  border: none;
  margin: 0 auto;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
  border-radius: 8px;
  background-color: #fff;
}
.UserProfile-UserName{
  font-weight: 700;
  font-size: 16px;
  color: #424242;
}

.BusinessContact-Widget{
  margin-bottom: 10px;
}

@media(max-width: 768px){

  .tab-pane .FilterForm{
    padding: 0;
  }
  .BilanWrap{
    padding: 15px;
  }
  .BilanResualts-Row{
    height: 80px;
  }
  .BilanResualts-Row .BilanItem-Title h4{
    font-size: 16px;
  }
  .BilanResualts-Row .BilanItem-Value div{
    font-size: 16px;
  }
  .BilanItem-Title{
    font-size: 12px;
  }
  .BilanItem-Value{
    font-size: 12px;
  }

}
/* ### COLLAPSE ### */

.card{
  border-radius: 8px;
  border: none;
  margin-bottom: 15px;
}
.card-header{
  background: transparent;
  border: none;
  position: relative;
}
.card-header:hover{
  cursor: pointer;
}
.card-header .CardTitle{
  display: block;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #6A0CAC;
  font-weight: 700;
  width: 100%;
  text-align: left;
  padding: 12px 10px;
}
.card-header .ProfileEdit-BTN{
  position: absolute;
  top: 22px;
  right: 30px;
}
.card:hover .ProfileEdit-BTN{
  background-color: #6A0CAC;
  color: #fff;
}
.card-body{
  padding-top: 0;
}
#TeamMembers .card-body{
  padding-left: 0;
  padding-right: 0;
}

.Export-Section .Settings-Field{
  margin-bottom: 10px;
}

/* ### PROGRAME SETTINGS ### */

.FlexBox{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.Programe-Settings_BOX{
  width: 50%;
  min-width: 50%;
  padding: 30px;
  border-radius: 8px;
  background-color: #fff;
}
.Programe-Settings_BOX .LoginForm-Title{
  margin-top: 0;
  font-size: 22px;
  text-align: center;
}
.ProgrameADD-BTN{
  background-color: #6A0CAC;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 2px solid #6A0CAC;
  margin-top: 15px;
  transition: all .15s cubic-bezier(.62,.26,.07,1);
}
.ProgrameADD-BTN:hover{
  background-color: transparent;
  color: #6A0CAC;
  cursor: pointer;
}
.MobileFilter{
  display: none;
}
.CloseMobileFilter{
  display: none;
}
@media (max-width: 1200px) {
  .Programe-Settings_BOX{
    width: 80%;
    min-width: 80%;
  }
}
@media(max-width: 768px){
  .Programe-Settings_BOX{
    width: 95%;
    min-width: 95%;
  }
  .MobileFilter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    border: none;
    border-radius: 8px;
    padding: 8px 15px;
    font-size: 12px;
    color: #6A0CAC;
    float: right;
  }
  .MobileFilter i{
    font-size: 18px;
    margin-right: 6px;
  }
  .EmailAlert{
    position: relative;
    bottom: 0;
    left: 0;
    padding: 0 10px;
    margin-top: 20px;
  }
  .card-body{
    padding: 0 10px 15px;
  }
  .card-header .CardTitle{
    padding: 12px 0px;
  }
  .TabContent .TableActions-Form{
    padding: 6px 5px 0;
  }
  .Porpal-BTN{
    padding: 0 10px;
    height: 40px;
  }
  .White-BTN{
    height: 30px;
    width: 30px;
  }
  .buttons-remove::before,
  .buttons-edit::before{
    font-size: 18px;
  }
  .TableActions-FormRowRight input[type="search"]{
    width: 150px;
    font-size: 12px;
  }
  .TableActions-FormRowRight label{
    margin: 0;
  }
  .MobileFilter{
    display: block;
  }
  .FilterRow{
    opacity: 0;
    visibility: hidden;
    transition: all .40s linear;
    position: fixed;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
    display: block;
    z-index: 99;
    background: #fff;
    padding: 30px;
    box-shadow: 0 0 10px 0 rgba(0,0,0, .06);
    border-radius: 8px;
  }
  .FilterRowToggle{
    opacity: 1;
    visibility: visible;
  }
  .FilterRowToggle .Input-Row{
    flex: 0 0 100%;
  }
  .FilterRow .Input-Row:last-child{
    margin-bottom: 0;
  }
  .Filter-BTN{
    background-color: #faf2ff;
  }
  .Filter-BTN:last-child{
    margin-bottom: 0;
  }
  .FilterForm{
    position: relative;
  }
  .FilterForm::before{
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    opacity: 0;
    z-index: 9;
    visibility: hidden;
    transition: all .30s linear;
  }
  .ActiveFilterForm::before{
    opacity: .8;
    visibility: visible;
  }
  .FilterForm .SelectFilter,
  .FilterRowToggle .InputField{
    background-color: #FAF2FF;
  }
  .CloseMobileFilter{
    position: fixed;
    right: 15px;
    top: 15px;
    background: #fff;
    color: #6A0CAC;
    opacity: 0;
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0,0,0, .05);
    visibility: hidden;
    font-size: 26px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
    transition: all .20s linear;
    display: block;
  }
  .ActiveFilterForm .CloseMobileFilter{
    visibility: visible;
    opacity: 1;
  }
  .ButtonAction .FilterRow{
    opacity: 1;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    visibility: visible;
    background-color: transparent;
    box-shadow: none;
  }
  .ButtonAction .FilterRow .Done-BTN{
    padding: 0 15px;
    width: auto;
    text-align: center;
    float: right;
  }
  .ButtonAction .FilterRow .Done-BTN span{
    /* display: none; */
  }
  .ButtonAction .FilterRow .Done-BTN i{
    margin: 0;
  }
  .FlexBox{
    height: 100vh;
  }
}

.daterangepicker{
  border-radius: 8px;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0,0,0, .08);
}
.daterangepicker::before{
  border-bottom: 0;
}
.daterangepicker td,
.daterangepicker th{
  transition: all .20s linear;
}
.daterangepicker td.in-range{
  background-color: #FAF2FF;
}
.daterangepicker td.active,
.daterangepicker td.active:hover{
  background-color: #6A0CAC;
}
.daterangepicker td:hover,
.daterangepicker th:hover{
  color: #fff;
  background-color: #6A0CAC;
}
.applyBtn{
  background-color: #6A0CAC;
  border: 2px solid #6A0CAC;
  padding: 8px 15px !important;
}
.cancelBtn{
  padding: 8px 15px !important;
}
.applyBtn:hover{
  background-color: #fff;
  color: #6A0CAC;
  border-color: #6A0CAC;
}
.btn-primary.disabled, .btn-primary:disabled{
  color: #333;
  background-color: #FAF2FF;
  border-color: #FAF2FF;
}
.Generate-BTN{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #6A0CAC;
  color: #fff;
  height: 52px;
  border: none;
  padding: 0 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 12px;
}

.investissement-select {
  border: none;
}

.form-control {
  background: #FAF2FF;
  border: none;
}

.form-control.FieldText-White {
  background-color: #fff
}

.input-group-text {
  background: #FAF2FF;
  border: 1px solid #ede6f2;
}

.FieldText-readOnly {
  background-color: #000 !important;
  color: #e9e9e9;
  border: none;
}

.stayToPay {
  background-color: #EF5350 !important;
  color: #e9e9e9;
  border: none;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #6A0CAC;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.align-right{
  text-align: right;
}

.align-right span {
  color: #6A0CAC;
  font-weight: 700;
}

.highlightOption {
  background: #6a0cac !important
}

.multiSelectContainer li.option:hover {
  background: #6a0cac91 !important
}

.Checkbox-inviteAll {
  float: right;
}